import React from 'react'
import './Mission.css';
import { Fade, Slide } from 'react-awesome-reveal';
import mission from "../assets/MobileDevice.webp";


export default function Mission() {
  return (
    <section className="mission-section py-5">
    <div className="container">
      <div className="row align-items-center">
        {/* Image Column with Slide Animation */}
        <div className="col-md-6 order-md-2 mb-4 mb-md-0">
          <Slide direction="right" triggerOnce>
            <img
              src={mission}
              alt="Our Mission"
              className="img-fluid shadow"
              style={{height: "350px", width: "100%", objectFit: "cover",borderRadius: "20px"}}
            />
          </Slide>
        </div>

        {/* Text Column with Fade Animation and Left Alignment */}
        <div className="col-md-6 order-md-1 text-start">
          <Fade direction="left" triggerOnce>
            <h2 className="mb-4 text-mission">Our Mission</h2>
            <p className="lead text-muted">
              Teskha Technologies was founded with a mission to drive innovation in education. Our aim is to bring together the very best education technology — solutions, products, professionals, and projects from around the world under one roof, supporting schools in overcoming barriers to innovation and digital transformation.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </section>
  )
}
