// MobileDeviceManagement.js
import React from "react";
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import MobileDeviceManagement1 from "../assets/MobileDeviceManagement1.webp";
import MobileDeviceManagement2 from "../assets/MobileDeviceManagement2.webp";
import styles from "./MobileDeviceManagement.module.css";
import {
  FaDesktop,
  FaGlobe,
  FaLock,
  FaCheckCircle,
  FaChartBar,
  FaFileAlt,
  FaSearch,
} from "react-icons/fa";

const MobileDeviceManagement = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
              Mobile Device Management - Cross Platform/OS
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold textJustify">
              The initiative aims to update the conventional directory structure
              that provides a cloud-based platform specifically engineered to
              safeguard identities, oversee devices, and guarantee secure access
              to a range of IT resources.
            </p>
          </Fade>
        </div>
      </section>
      <section className={`${styles.InteractiveSubSections} py-5`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Image Column with Slide Animation */}
            <div className="col-md-6 order-md-2 mb-4 mb-md-0">
              <Slide direction="right" triggerOnce>
                <img
                  src={MobileDeviceManagement1}
                  alt="Our Mission"
                  className="img-fluid shadow"
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Slide>
            </div>

            {/* Text Column with Fade Animation and Left Alignment */}
            <div className="col-md-6 order-md-1 text-start">
              <Fade direction="left" triggerOnce>
                <h2 className={`mb-2 ${styles.textHeadings}`}>
                  Versatile Access Management
                </h2>
                <p className="lead text-muted">
                  The platform is compatible with multiple operating systems,
                  including Windows, Mac, and Linux. It is versatile enough to
                  accommodate various configurations, whether deployed
                  on-premises or in the cloud. Its primary objective is to
                  deliver secure and adaptable access and management solutions
                  tailored for contemporary IT environments.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <div className={`${styles.FeatureSectionContainer} text-start`}>
        <section className={`${styles.featuresSection} ${styles.fadeIn}`}>
          <h2 className={styles.sectionTitle}>Exclusive Features</h2>
          <div className={styles.featuresGrid}>
            {[
              {
                text: "Manage Windows, Mac, & Linux Devices",
                icon: <FaDesktop />,
              },
              { text: "Centralized OS Management", icon: <FaGlobe /> },
              { text: "Secure Remote & On-Prem Devices", icon: <FaLock /> },
              { text: "Enforce Compliance Policies", icon: <FaCheckCircle /> },
              { text: "Detailed OS Insights & Support", icon: <FaChartBar /> },
              { text: "Meet Compliance Standards", icon: <FaFileAlt /> },
              { text: "End-to-End Device Insights", icon: <FaSearch /> },
            ].map((feature, index) => (
              <div key={index} className={styles.featureCard}>
                <span className={styles.featureIcon}>{feature.icon}</span>
                <p className={styles.featureText}>{feature.text}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
      <section className={`${styles.InteractiveSubSections} py-5`}>
          <div className="container">
            <div className="row align-items-center">
              {/* Image Column with Slide Animation */}
              <div className="col-md-6 order-md-1 mb-4 mb-md-0">
                <Slide direction="left" triggerOnce>
                  <img
                    src={MobileDeviceManagement2}
                    alt="Our Vision"
                    className="img-fluid shadow"
                    style={{
                      height: "300px",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                </Slide>
              </div>

              {/* Text Column with Fade Animation and Left Alignment */}
              <div className="col-md-6 order-md-2 text-start">
                <Fade direction="right" triggerOnce>
                  <h2 className={`mb-2 ${styles.textHeadings}`}>
                    Identity and Directory Management
                  </h2>
                  <p className="lead text-muted">
                    This platform also includes advanced identity and directory
                    management capabilities, allowing centralized control and
                    management of user identities across all platforms and
                    operating systems.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
};

export default MobileDeviceManagement;
