import React from 'react'
import './Identity.css';
import { Fade, Slide } from 'react-awesome-reveal';
import vision from "../assets/vision.webp";


export default function Identity() {
  return (
    <section className="mission-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column with Slide Animation */}
          <div className="col-md-6 order-md-1 mb-4 mb-md-0">
            <Slide direction="left" triggerOnce>
              <img
                src={vision}
                alt="Our Vision"
                className="img-fluid shadow"
                style={{height: "350px", width: "100%", objectFit: "cover",borderRadius: "20px"}}
              />
            </Slide>
          </div>

          {/* Text Column with Fade Animation and Left Alignment */}
          <div className="col-md-6 order-md-2 text-start">
            <Fade direction="right" triggerOnce>
              <h2 className="mb-4 text-identity">Our Identity</h2>
              <p className="lead text-muted">
              Our distinguishing feature lies in our steadfast dedication to understanding and addressing the unique requirements of each client. Whether your objective is to enhance operational efficiency, increase productivity, or explore innovative digital avenues, we are committed to actualizing your vision. With extensive expertise and a profound enthusiasm for technology, we focus on facilitating significant digital transformations that advance your business into the future.              </p>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  )
}
