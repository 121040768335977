import React from "react";
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import InteractiveSub1 from "../assets/InteractiveSubSection.webp";
import InteractiveSub2 from "../assets/InteractiveSubSection2.webp";
import styles from "./InteractiveSolutions.module.css"; // Importing the CSS module for scoped styling

const InteractiveSolutions = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">Interactivity Solutions</h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
              Welcome to a new era of interactive education! Enhance engagement
              in your classrooms with our tailored interactive solutions
              designed for educational institutions across all levels. Our
              cutting-edge interactive displays and software tools will enable
              you to develop inspiring, contemporary classrooms and dynamic
              learning environments.
            </p>
          </Fade>
        </div>
      </section>
      <section className={`${styles.InteractiveSubSections} py-5`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Image Column with Slide Animation */}
            <div className="col-md-6 order-md-2 mb-4 mb-md-0">
              <Slide direction="right" triggerOnce>
                <img
                  src={InteractiveSub1}
                  alt="Our Mission"
                  className="img-fluid shadow"
                  style={{
                    height: "350px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Slide>
            </div>

            {/* Text Column with Fade Animation and Left Alignment */}
            <div className="col-md-6 order-md-1 text-start">
              <Fade direction="left" triggerOnce>
                <h2 className={`mb-2 ${styles.textMission}`}>
                  Interactive Education and Collaboration
                </h2>
                <p className="lead text-muted">
                  Enhance student engagement by establishing an appropriate
                  learning atmosphere through our interactive displays and
                  software solutions. Foster interaction among students and
                  promote innovative collaborative opportunities with a diverse
                  range of applications and educational games.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.InteractiveSubSections} py-5`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Image Column with Slide Animation */}
            <div className="col-md-6 order-md-1 mb-4 mb-md-0">
              <Slide direction="left" triggerOnce>
                <img
                  src={InteractiveSub2}
                  alt="Our Vision"
                  className="img-fluid shadow"
                  style={{
                    height: "350px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Slide>
            </div>

            {/* Text Column with Fade Animation and Left Alignment */}
            <div className="col-md-6 order-md-2 text-start">
              <Fade direction="right" triggerOnce>
                <h2 className={`mb-2 ${styles.textMission}`}>
                  Practical and User-Friendly
                </h2>
                <p className="lead text-muted textJustify">
                  Our interactive displays are designed for educators of all
                  ages, allowing for quick and easy adoption. They are equipped
                  with a range of practical training tools. With unique
                  innovative software solutions, users can operate the
                  interactive display as they would a traditional blackboard,
                  perform virtual and visual scientific experiments, enhance
                  math lessons with a multitude of math and geometry tools, or
                  explore human anatomy in three-dimensional views. The
                  potential applications of our interactive display are
                  limitless.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InteractiveSolutions;
