import React from 'react'
import './whatsets.css';
import { Zoom, Fade } from 'react-awesome-reveal';


export default function WhatSets() {
  return (
    <section className="full-width-section py-5 text-center text-white">
      <div className="container">
        {/* Title with Zoom Animation */}
        <Zoom triggerOnce>
          <h2 className="display-5 fw-bold mb-3">What sets us apart</h2>
        </Zoom>

        {/* Description with Fade Animation */}
        <Fade direction="up" delay={200} triggerOnce>
          <p className="lead mb-0 whatSet-pragraph">
            At Teskha Technologies, we strive not only to meet expectations but to surpass them by taking ownership of your challenges and developing tailored solutions that ensure enduring success.
          </p>
        </Fade>
      </div>
    </section>
  )
}
