import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./NetworkingCablingSolutions.module.css";

const NetworkingCablingSolutions = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
              High-end Networking and Cabling Solutions
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
              Our passive solutions and expert team provide a responsive, fast,
              and cost-effective solution.
            </p>
          </Fade>
        </div>
      </section>
      <div className={`${styles.container} text-start`}>
        
        <section className={styles.servicesSection}>
          <h2 className={styles.sectionTitle}>Our Services:</h2>
          <div className={styles.cardContainer}>
            <div className={styles.card}>Structured Cabling</div>
            <div className={styles.card}>Cable Laying</div>
            <div className={styles.card}>Patch Cords Supply</div>
            <div className={styles.card}>Labeling</div>
            <div className={styles.card}>
              Rack Installation & Patch Panel Management
            </div>
            <div className={styles.card}>Mounting</div>
            <div className={styles.card}>UPS Installation</div>
            <div className={styles.card}>Power Management</div>
            <div className={styles.card}>Preventive Maintenance</div>
            <div className={styles.card}>
              Support and Physical Infrastructure
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NetworkingCablingSolutions;
