// import React from "react";
// import { Carousel } from "react-bootstrap";
// import "./HeroSection.css"; 

// const HeroSection = () => {
//   return (
//     <div className="hero-section">
//       <Carousel fade interval={4000}>
//         <Carousel.Item className="carousel-item-1">
//           <div className="d-block w-100">
//             <div
//               className="d-flex justify-content-center align-items-center"
//               style={{ height: "100%" }}
//             >
//               <h1 className="text-white text-center hero-heading">
//               Your Partner in Educational Innovation
//               </h1>
//             </div>
//           </div>
//         </Carousel.Item>
//         <Carousel.Item className="carousel-item-2">
//           <div className="d-block w-100">
//             <div
//               className="d-flex justify-content-center align-items-center"
//               style={{ height: "100%" }}
//             >
//               <h1 className="text-white text-center hero-heading">Revolutionizing Learning with Technology</h1>
//             </div>
//           </div>
//         </Carousel.Item>
//         <Carousel.Item className="carousel-item-3">
//           <div className="d-block w-100">
//             <div
//               className="d-flex justify-content-center align-items-center"
//               style={{ height: "100%" }}
//             >
//               <h1 className="text-white text-center hero-heading">Empowering Education Through Innovation</h1>
//             </div>
//           </div>
//         </Carousel.Item>
//       </Carousel>
//     </div>
//   );
// };

// export default HeroSection;



import React from "react";
import { Carousel } from "react-bootstrap";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <Carousel interval={4000}> {/* Removed the fade property */}
        <Carousel.Item className="carousel-item-1">
          <div className="d-block w-100">
            <div className="d-flex justify-content-center align-items-center hero-content">
              <h1 className="text-white text-center hero-heading">
                Your Partner in Educational Innovation
              </h1>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-2">
          <div className="d-block w-100">
            <div className="d-flex justify-content-center align-items-center hero-content">
              <h1 className="text-white text-center hero-heading">
                Revolutionizing Learning with Technology
              </h1>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-3">
          <div className="d-block w-100">
            <div className="d-flex justify-content-center align-items-center hero-content">
              <h1 className="text-white text-center hero-heading">
                Empowering Education Through Innovation
              </h1>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HeroSection;
