import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaShieldAlt, FaRocket, FaDollarSign, FaCogs } from "react-icons/fa";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./LearningTeachingDevices.module.css";

const LearningTeachingDevices = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
              Learning & Teaching Devices
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold">
              Choose the technology that meet your needs and empowers your
              students with flexible options. We offer recommendations but
              encourage you to select devices that best suit you and your
              classroom.
            </p>
          </Fade>
        </div>
      </section>
      <Container className="py-5">
        <p className="lead text-secondary mx-auto my-5">
          Our suggested devices include Chromebooks, Windows laptops, iPads, and
          Android tablets, tailored for educational use.
        </p>

        <Row>
          <Col md={6} lg={3}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body className={styles.cardBody + " text-center"}>
                <FaShieldAlt size={40} className="mb-3" />
                <Card.Title className={styles.cardTitle}>Secure</Card.Title>
                <Card.Text className={styles.cardTextIcon}>
                  Ensure data safety with advanced security features.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body className={styles.cardBody + " text-center"}>
                <FaRocket size={40} className="mb-3" />
                <Card.Title className={styles.cardTitle}>Fast</Card.Title>
                <Card.Text className={styles.cardTextIcon}>
                  Experience quick performance to maximize productivity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body className={styles.cardBody + " text-center"}>
                <FaDollarSign size={40} className="mb-3" />
                <Card.Title className={styles.cardTitle}>Affordable</Card.Title>
                <Card.Text className={styles.cardTextIcon}>
                  Choose cost-effective solutions that fit your budget.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body className={styles.cardBody + " text-center"}>
                <FaCogs size={40} className="mb-3" />
                <Card.Title className={styles.cardTitle}>Robust</Card.Title>
                <Card.Text className={styles.cardTextIcon}>
                  Reliable devices built to handle educational demands.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Card className={styles.shadowSm}>
              <Card.Body className={styles.cardBody}>
                <Card.Title className={styles.cardTitle}>
                  Educational Devices Overview
                </Card.Title>
                <Card.Text className={`${styles.cardText} text-start`}>
                  We ensure that the devices we suggest are tailored for
                  educational settings, facilitating a distinctive approach to
                  information processing and engagement both inside and outside
                  the classroom.
                </Card.Text>
                <ol>
                  <li>Zero Touch Enrollment</li>
                  <li>Cross-platform Mobile Device Management options</li>
                  <li>
                    Chromebooks with Chrome Education Upgrade License built-in
                  </li>
                </ol>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body
                className={`${styles.cardBody} ${styles.ResponsiveCards}`}
              >
                <Card.Title className={styles.cardTitle}>
                  Chromebooks
                </Card.Title>
                <Card.Text className={styles.cardText}>
                  Chromebooks provide users with access to Google's extensive
                  array of educational applications and are capable of
                  performing a range of functions, such as word processing and
                  video editing. They feature rapid boot-up times and include
                  the Chrome admin console, which facilitates large-scale
                  management for educational organizations.
                  <br />
                </Card.Text>
                <Card.Text className={styles.cardText}>
                  Our selection of Chromebooks is varied, catering to the needs
                  of every student, educator, and financial plan. We collaborate
                  closely with school districts and technology integrators to
                  identify the most suitable technological solutions,
                  guaranteeing seamless learning experiences irrespective of the
                  location of classes.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body
                className={`${styles.cardBody} ${styles.ResponsiveCards}`}
              >
                <Card.Title className={styles.cardTitle}>
                  Windows Devices
                </Card.Title>
                <Card.Text className={styles.cardText}>
                  Windows devidesis engineered to transform hybrid learning,
                  providing an economical option that maintains high performance
                  standards. It is equipped with a variety of features aimed at
                  enhancing students' success in virtual learning environments,
                  all while being conveniently portable.
                </Card.Text>
                <Card.Text className={styles.cardText}>
                  Opt for the Windows devices Pro or Laptop Go; both are
                  specifically crafted for educational settings. With robust
                  construction, secure software, and a focus on the needs of
                  learners, these devices represent an excellent selection for
                  educational institutions.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card className={styles.shadowSm + " mb-4"}>
              <Card.Body className={styles.cardBody}>
                <Card.Title className={styles.cardTitle}>
                  Get Trained on Your New Devices
                </Card.Title>
                <Card.Text className={styles.cardText}>
                  We offer a range of training sessions for teachers and staff
                  to learn all about their new tech and make sure they get the
                  most out of it.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Additional Rows as in Original Code */}
      </Container>
    </div>
  );
};

export default LearningTeachingDevices;
