import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ChargeStorage from "../assets/ChargingStorage.webp";
import ChargeStorage2 from "../assets/ChargeSolution2.webp";
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import styles from "./ChargingStorageSolution.module.css";

const ChargingStorageSolution = () => {
  return (
    <div className="PaddingForApp Margin-TopAllService">
      <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
              Charging and storage solutions
            </h1>
          </Zoom>
        </div>
      </section>
      <section className={`py-5`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Text Column with Fade Animation and Left Alignment */}
            <div className="col-md-6 order-md-1 text-start">
              <Fade direction="left" triggerOnce>
                <p className="lead text-muted textJustify">
                  charge & sync carts feature a user-friendly design and
                  supports concurrent charging of iPads and Android tablets,
                  Chromebooks and laptops up to 16 inches. Our devices are
                  designed with child safety in mind and are exceptionally
                  well-suited for any classroom with young students. Therefore,
                  our charging solution is the perfect choice for any school
                  using large-scale tablet deployment.
                </p>
              </Fade>
            </div>
            {/* Image Column with Slide Animation */}
            <div className="col-md-6 order-md-2 mb-4 mb-md-0">
              <Slide direction="right" triggerOnce>
                <img
                  src={ChargeStorage}
                  alt="Charge-Storage"
                  className="img-fluid shadow"
                  style={{
                    height: "350px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Slide>
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.InteractiveSubSections} py-5`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Image Column with Slide Animation */}
            <div className="col-md-6 order-md-1 mb-4 mb-md-0">
              <Slide direction="left" triggerOnce>
                <img
                  src={ChargeStorage2}
                  alt="Our Vision"
                  className="img-fluid shadow"
                  style={{
                    height: "290px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Slide>
            </div>

            {/* Text Column with Fade Animation and Left Alignment */}
            <div className="col-md-6 order-md-2 text-start">
              <Fade direction="right" triggerOnce>
                <p className="lead text-muted">
                  Our Educational Charging and Sync cart products addresses
                  based on your need and usability keeping in mind, versatility,
                  cable management & ventilation, quality, safety,
                  maneuverability, security, cost & warranty.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChargingStorageSolution;
