import React from 'react'
import About from './About'
import WhatSets from './WhatSets'
import Mission from './Mission'
import Identity from './Identity'
import "./AboutUsPage.css"

const AboutUsPage = () => {
  return (
    <div className='AboutUsPage-Padding'>
      <About/>
      <WhatSets/>
      <Mission/>
      <Identity/>
    </div>
  )
}

export default AboutUsPage
