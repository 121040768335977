import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./NavbarComponent.css";
import Logo from "../logo-symbol.png";

const NavbarComponent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const menuItemStyle = {
    color: isHomePage ? "white" : "black",
  };

  const [scrolling, setScrolling] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(true); // State for the dropdown menu

  const handleScroll = () => {
    setScrolling(window.scrollY > 20);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown state
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 992) {
      setDropdownOpen(false); // Close dropdown when a link is clicked
      setSidebarVisible(false); // Close the sidebar if it's open
    }
    setDropdownOpen(!dropdownOpen)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        className={`navbar navbar-expand-lg navbarSetting ${
          scrolling ? "bg-white" : "bg-transparent"
        } transition-all`}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} alt="Logo" className="image-setting" />
            <span
              className={`LogoHandle ${scrolling ? "nav-link-black" : ""}`}
              style={menuItemStyle}
            >
              Teskha
            </span>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={toggleSidebar} // Close or open the sidebar on mobile click
          />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                as={Link}
                to="/"
                onClick={handleLinkClick} // Close navbar on link click
                style={menuItemStyle}
                className={scrolling ? "nav-link-black" : ""}
              >
                Home
              </Nav.Link>

              <div className="dropdown">
                <Nav.Link
                  onClick={toggleDropdown} // Toggle dropdown on click
                  className={`dropdown-toggle ${
                    scrolling ? "nav-link-black" : "productsBlack"
                  }`}
                  style={menuItemStyle}
                >
                  Products & Solutions
                </Nav.Link>
                {dropdownOpen && (
                  <div className="dropdown-menu">
                    <Link
                      to="/Learning-and-Teaching-Devices"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Learning and Teaching Devices
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Charging-and-Storage-Solution"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Charging and Storage Solutions
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Mobile-Device-Management"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Mobile Device Management - Cross Platform/OS
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Interactive-Solutions"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Interactivity Solutions
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Networking-Cabling-Solutions"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      High-end Networking and Cabling Solutions
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Immersive-Learning"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Immersive Learning Spaces
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/Student-Well-Being"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Student's Well-being
                    </Link>
                  </div>
                )}
              </div>

              <Nav.Link
                as={Link}
                to="/about-us"
                onClick={handleLinkClick}
                className={scrolling ? "nav-link-black" : ""}
                style={menuItemStyle}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={handleLinkClick}
                className={scrolling ? "nav-link-black" : ""}
                style={menuItemStyle}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
