import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import styles from "./ImmersiveLearning.module.css";
import FullyInteractive from "../assets/FullyInteractive.webp";
import CreateScenario from "../assets/CreateScenario.webp";
import LimitlessExperience from "../assets/LimitlessExperience.webp";
import Training from "../assets/TrainingSupport.webp";

export default function ImmersiveLearning() {
  return (
    <>
    <div className="PaddingForApp Margin-TopAllService">
    <section
        className={`${styles.studentWellbeingHeader} py-5 text-center text-white`}
      >
        <div className={styles.container}>
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">
            Immersive Learning Spaces
            </h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold textJustify">
            Immersive Learning Spaces are transforming the educational landscape
            by providing students with an extraordinary experience in virtual
            environments, all without the need for a headset. These cutting-edge
            facilities enable learners to move beyond the limitations of
            conventional education, creating a setting that encourages the
            development of foundational knowledge, the exploration of new
            frontiers, and the investigation of subjects that extend beyond the
            traditional classroom.
            </p>
          </Fade>
        </div>
      </section>
    
    <div className={`${styles.container} container my-5`}>
      <div className="row justify-content-center">
        {/* Card 1: Fully Interactive */}
        <div className="col-md-3 col-sm-6 mb-4">
          <div className={`card ${styles.card} shadow-lg`}>
            <img src={FullyInteractive} className={`card-img-top ${styles.img}`} alt="Interactive" />
            <div className="card-body">
              <h5 className={`card-title text-center ${styles.cardTitle}`}>FULLY INTERACTIVE</h5>
              <p className={`card-text text-start ${styles.cardText}`}>Immerse yourself completely. Interact with every surface, add sounds, smells, and sensory effects.</p>
            </div>
          </div>
        </div>

        {/* Card 2: Create Your Own Scenarios */}
        <div className="col-md-3 col-sm-6 mb-4">
          <div className={`card ${styles.card} shadow-lg`}>
            <img src={CreateScenario} className={`card-img-top ${styles.img}`} alt="Create Scenarios" />
            <div className="card-body">
              <h5 className={`card-title text-center ${styles.cardTitle}`}>CREATE YOUR OWN SCENARIOS</h5>
              <p className={`card-text text-start ${styles.cardText}`}>Create the exact environment you want. From emergency roadside scenarios to the forests of Narnia, our rooms are fully customizable to your needs.</p>
            </div>
          </div>
        </div>

        {/* Card 3: Limitless Experiences */}
        <div className="col-md-3 col-sm-6 mb-4">
          <div className={`card ${styles.card} shadow-lg`}>
            <img src={LimitlessExperience} className={`card-img-top ${styles.img}`} alt="Limitless Experiences" />
            <div className="card-body">
              <h5 className={`card-title text-center ${styles.cardTitle}`}>LIMITLESS EXPERIENCES</h5>
              <p className={`card-text text-start ${styles.cardText}`}>Choose from hundreds of pre-built scenarios or build your own with our template-based software. You can easily create and edit your immersion space exactly how you see fit. The only limit is your imagination.</p>
            </div>
          </div>
        </div>

        {/* Card 4: Training & Support */}
        <div className="col-md-3 col-sm-6 mb-4">
          <div className={`card ${styles.card} shadow-lg`}>
            <img src={Training} className={`card-img-top ${styles.img}`} alt="Training & Support" />
            <div className="card-body">
              <h5 className={`card-title text-center ${styles.cardTitle}`}>TRAINING & SUPPORT</h5>
              <p className={`card-text text-start ${styles.cardText}`}>From day one our team works with you to create exactly what you want. With professional training and ongoing support, your immersive space will always perform at its best.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
    </>
  );
}
