import React from "react";
import "./Studentwellbeing.css";
import { Fade, Zoom, Slide , Bounce } from "react-awesome-reveal";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineVapeFree } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ImMeter } from "react-icons/im";
import { PiMonitorArrowUpFill } from "react-icons/pi";
import { MdControlCamera } from "react-icons/md";
import detect from "../assets/detect.webp";
import students from "../assets/students.jpg";
import styles from "./vapdetection.module.css";

export default function StudentWellBeing() {
  return (
    <>
    <div className="PaddingForApp Margin-TopAllService">
      <section className="student-wellbeing-header py-5 text-center text-white">
        <div className="container">
          {/* Title with Zoom Animation */}
          <Zoom triggerOnce>
            <h1 className="display-4 fw-bold mb-3">Students Well-being</h1>
          </Zoom>

          {/* Description with Fade Animation */}
          <Fade direction="up" triggerOnce delay={300}>
            <p className="lead fw-bold textJustify">
              Vaping Endangers Teenagers’ Health. Experts warn that teenage
              vaping poses a serious threat, with adolescents using it for the
              thrill and novelty of different flavors, as well as to fit in
              socially. Not only can nicotine inhalation hinder their growth and
              development, but it also harms the developing brain, resulting in
              learning difficulties, memory impairment, seizures, and mental
              health disorders.
            </p>
          </Fade>
        </div>
      </section>

      <section className="features-section py-5 bg-light">
        <div className="container">
          <div className="row text-center">
            {/* Card 1 */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <h5 className="card-title text-WebsiteColor">20%</h5>
                  <p className="card-text text-muted">
                    Increase in Mental Health issues among young vapers
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <h5 className="card-title text-WebsiteColor">71%</h5>
                  <p className="card-text text-muted">
                    Increased risk of stroke among e-cigarette users
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <h5 className="card-title text-WebsiteColor">60%</h5>
                  <p className="card-text text-muted">
                    Increased risk of nicotine addiction among teenagers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="centered-section py-5">
        <div className="container text-center">
          <h2 className="display-6 fw-bold mb-3">Vape Detection Made Easy</h2>
          <p className="lead mb-0">
            FlySense &reg; vape sensors provide environmental intelligence that
            alloaws a possibly dangerous or harmful situation to be addressed in
            a timely and effective manner -- thus averting potential disaster.
          </p>
        </div>
      </section>

      <section className="card-section py-5 bg-light">
      <div className="container">
        <div className="row text-center">
          {/* Card 1 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={100}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={200}>
                    <HiOutlineRefresh size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Seamless Integration</h5>
                  <p className="card-text text-muted">
                    Integrates seamlessly with Camera Systems (VMS) and Access Controls.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          {/* Card 2 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={200}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={300}>
                    <MdOutlineVapeFree size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Vaping Detection</h5>
                  <p className="card-text text-muted">
                    Detects the vaping of THC, Nicotine, and other vaping products.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          {/* Card 3 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={300}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={400}>
                    <IoMdCheckmarkCircleOutline size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Reliable Warranty</h5>
                  <p className="card-text text-muted">
                    All Soter Technology hardware purchases are backed by our 5-year hardware warranty.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          {/* Card 4 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={400}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={500}>
                    <ImMeter size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Real-time Detection</h5>
                  <p className="card-text text-muted">
                    Real-time vape and sound anomalies detector.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          {/* Card 5 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={500}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={600}>
                    <PiMonitorArrowUpFill size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Air Quality Monitoring</h5>
                  <p className="card-text text-muted">
                    Monitors air quality and potential bullying.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          {/* Card 6 */}
          <div className="col-md-4 mb-4">
            <Fade triggerOnce direction="up" delay={600}>
              <div className="card shadow-lg border-0 rounded-lg h-100">
                <div className="card-body">
                  <Zoom triggerOnce delay={700}>
                    <MdControlCamera size={50} className="mb-3 text-danger" />
                  </Zoom>
                  <h5 className="card-title text-dark">Enhance Control</h5>
                  <p className="card-text text-muted">
                    Empowers you to gain control of areas where you cannot place a camera or microphone.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>

      <section className={`py-4 ${styles.section}`}>
        <div className="container">
          <div className="row align-items-center">
            {/* Description Column with Slide Animation */}
            <div className="col-md-6">
              <Slide direction="left" triggerOnce>
                <p className={`lead ${styles.description}`}>
                  Make a powerful impact globally by addressing the issues of
                  vaping and bullying. Stand with the world in fostering safer
                  and healthier campuses, employing our <b>FlySense®</b> vape
                  detector and potential bullying solutions. Together, we can
                  enact change and create a healthier environment for all.
                </p>
              </Slide>
            </div>

            {/* Small Image Column with Fade Animation */}
            <div className="col-md-6 text-center">
              <Fade direction="right" triggerOnce>
                <img
                  src={detect}
                  alt="Innovation"
                  className={`img-fluid ${styles.image}`}
                />
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section className={`py-5 ${styles.countingSection}`}>
        <div className="container">
          <div className="row text-center">
            {/* Column 1 */}
            <div className={`col-md-4 ${styles.countColumn}`}>
              <Bounce triggerOnce>
                <h2 className={`display-4 fw-bold ${styles.customcolor}`}>
                  2,500+
                </h2>
                <p className="lead text-muted">Organizations with FlySense®</p>
              </Bounce>
            </div>

            {/* Column 2 */}
            <div className={`col-md-4 ${styles.countColumn}`}>
              <Bounce triggerOnce delay={200}>
                <h2 className={`display-4 fw-bold ${styles.customcolor}`}>22+</h2>
                <p className="lead text-muted">Countries with our FlySense® Solution</p>
              </Bounce>
            </div>

            {/* Column 3 */}
            <div className={`col-md-4 ${styles.countColumn}`}>
              <Bounce triggerOnce delay={400}>
                <h2 className={`display-4 fw-bold ${styles.customcolor}`}>
                  30,000+
                </h2>
                <p className="lead text-muted">Active FlySense® Devices</p>
              </Bounce>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}
