import React from 'react'
import HeroSection from './HeroSection'
import About from './About'
import Mission from './Mission'
import Identity from './Identity'
import WhatSets from './WhatSets'

const Home = () => {
  return (
    <div>
      <HeroSection/>
      <About/>
      <WhatSets/>
      <Mission/>
      <Identity/>
    </div>
  )
}

export default Home
